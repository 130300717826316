import React from 'react';
import { Col, Row } from 'reactstrap';
import LogoItem from './LogoItem';
import ItemText from '../ItemText';

import logos from './logos';

const LogosDownload = () => (
  <Row className="row">
    <Col className="col-12 col-md-5 col-lg-5 order-first">
      <ItemText
        title="Logos para download"
        text="Clique no botão abaixo da marca respectivo ao formato que você precisa dela. Corel (cdr), Illustrator (ai), JPG ou PNG (fundo transparente)."
      />
    </Col>
    <Col className="col-12 col-md-7 order-last">
      <Row className="row mb-6">
        {logos &&
          logos.map(logo => (
            <LogoItem
              Image={logo.Image}
              alt={logo.title}
              text={logo.title}
              logosToDownload={logo.logosToDownload}
            />
          ))}
      </Row>
    </Col>
  </Row>
);

LogosDownload.propTypes = {};

export default LogosDownload;
