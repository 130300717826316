import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

const ProtectionAreaItem = ({ Image, alt, title }) => (
  <Col className="col-12" style={{ textAlign: 'center', padding: '5px' }}>
    <img src={Image} className="img-fluid" width={420} height={240} alt={alt} />
    <p>{title}</p>
  </Col>
);

ProtectionAreaItem.propTypes = {
  Image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ProtectionAreaItem;
