import DefaultImage from '../../../assets/manual_marca/logos/01-padrao.jpg';
import PurpleBackgroundImage from '../../../assets/manual_marca/logos/02-fundo-roxo.jpg';
import TurkishBackground from '../../../assets/manual_marca/logos/03-fundo-turquesa.jpg';
import GrayBackground from '../../../assets/manual_marca/logos/04-tom-cinza.jpg';

export default [
  {
    Image: DefaultImage,
    title: 'Padrão',
    logosToDownload: [
      {
        id: 1,
        title: '.PDF',
        href: '/manual_marca/logos/01-padrao.pdf',
        fileName: '01-padrao.pdf',
      },
      {
        id: 2,
        title: '.CDR',
        href: '/manual_marca/logos/01-padrao.cdr',
        fileName: '01-padrao.cdr',
      },
      {
        id: 3,
        title: '.AI',
        href: '/manual_marca/logos/01-padrao.zip',
        fileName: '01-padrao.zip',
      },
      {
        id: 4,
        title: '.JPG',
        href: '/manual_marca/logos/01-padrao.jpg',
        fileName: '01-padrao.jpg',
      },
      {
        id: 5,
        title: '.PNG',
        href: '/manual_marca/logos/01-padrao.png',
        fileName: '01-padrao.png',
      },
    ],
  },

  {
    Image: PurpleBackgroundImage,
    title: 'Fundo roxo',
    logosToDownload: [
      {
        id: 1,
        title: '.PDF',
        href: '/manual_marca/logos/02-fundo-roxo.pdf',
        fileName: '02-fundo-roxo.pdf',
      },
      {
        id: 2,
        title: '.CDR',
        href: '/manual_marca/logos/02-fundo-roxo.cdr',
        fileName: '02-fundo-roxo.cdr',
      },
      {
        id: 3,
        title: '.AI',
        href: '/manual_marca/logos/02-fundo-roxo.zip',
        fileName: '02-fundo-roxo.zip',
      },
      {
        id: 4,
        title: '.JPG',
        href: '/manual_marca/logos/02-fundo-roxo.jpg',
        fileName: '02-fundo-roxo.jpg',
      },
      {
        id: 5,
        title: '.PNG',
        href: '/manual_marca/logos/02-fundo-roxo.png',
        fileName: '02-fundo-roxo.png',
      },
    ],
  },

  {
    Image: TurkishBackground,
    title: 'Fundo turquesa',
    logosToDownload: [
      {
        id: 1,
        title: '.PDF',
        href: '/manual_marca/logos/03-fundo-turquesa.pdf',
        fileName: '03-fundo-turquesa.pdf',
      },
      {
        id: 2,
        title: '.CDR',
        href: '/manual_marca/logos/03-fundo-turquesa.cdr',
        fileName: '03-fundo-turquesa.cdr',
      },
      {
        id: 3,
        title: '.AI',
        href: '/manual_marca/logos/03-fundo-turquesa.zip',
        fileName: '03-fundo-turquesa.zip',
      },
      {
        id: 4,
        title: '.JPG',
        href: '/manual_marca/logos/03-fundo-turquesa.jpg',
        fileName: '03-fundo-turquesa.jpg',
      },
      {
        id: 5,
        title: '.PNG',
        href: '/manual_marca/logos/03-fundo-turquesa.png',
        fileName: '03-fundo-turquesa.png',
      },
    ],
  },

  {
    Image: GrayBackground,
    title: 'Tom de cinza',
    logosToDownload: [
      {
        id: 1,
        title: '.PDF',
        href: '/manual_marca/logos/04-tom-cinza.pdf',
        fileName: '04-tom-cinza.pdf',
      },
      {
        id: 2,
        title: '.CDR',
        href: '/manual_marca/logos/04-tom-cinza.cdr',
        fileName: '04-tom-cinza.cdr',
      },
      {
        id: 3,
        title: '.AI',
        href: '/manual_marca/logos/04-tom-cinza.zip',
        fileName: '04-tom-cinza.zip',
      },
      {
        id: 4,
        title: '.JPG',
        href: '/manual_marca/logos/04-tom-cinza.jpg',
        fileName: '04-tom-cinza.jpg',
      },
      {
        id: 5,
        title: '.PNG',
        href: '/manual_marca/logos/04-tom-cinza.png',
        fileName: '04-tom-cinza.png',
      },
    ],
  },
];
