import React from 'react';
import { Col, Row } from 'reactstrap';
import NotAllowedUsageItem from './NotAllowedUsageItem';
import ItemText from '../ItemText';

import imageA from '../../../assets/manual_marca/uso_nao_permitido/distorcer.png';
import imageB from '../../../assets/manual_marca/uso_nao_permitido/inverter.png';
import imageC from '../../../assets/manual_marca/uso_nao_permitido/converter_cores_erradas.png';
import imageD from '../../../assets/manual_marca/uso_nao_permitido/aplicar_cores_erradas.png';

const NotAllowedUsage = () => (
  <Row className="row">
    <Col className="col-12 col-md-5 col-lg-5 order-first">
      <ItemText
        title="Usos não permitidos"
        text="Para que a integridade do logotipo seja preservada, é necessário atentar-se aos usos indevidos de reprodução do mesmo, como apresentados ao lado."
      />
    </Col>
    <Col className="col-12 col-md-7 order-last">
      <Row className="row mb-6">
        <NotAllowedUsageItem
          Image={imageA}
          title="Distorcer ou deformar o logo"
        />
        <NotAllowedUsageItem
          Image={imageB}
          title="Inverter o logo verticalmente"
        />
        <NotAllowedUsageItem
          Image={imageC}
          title="Converter o logo para cores que não sejam aquelas de uso permitido"
        />
        <NotAllowedUsageItem
          Image={imageD}
          title="Aplicar o logo sobre cores que não sejam aquelas de uso permitido"
        />
      </Row>
    </Col>
  </Row>
);

NotAllowedUsage.propTypes = {};

export default NotAllowedUsage;
