import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import Jumbotron from '../../components/Jumbotron';
import { SECONDARY } from '../../utils/colors';
import LogosDownload from '../../components/Brandbook/LogosDownload';
import ChromaticPattern from '../../components/Brandbook/ChromaticPattern';
import ProtectionArea from '../../components/Brandbook/ProtectionArea';
import MinimumSizing from '../../components/Brandbook/MinimumSizing';
import NotAllowedUsage from '../../components/Brandbook/NotAllowedUsage';
import CompleteManual from '../../components/Brandbook/CompleteManual';

const BrandbookPage = ({ location }) => (
  <Layout theme="blue" location={location}>
    <SEO title="Manual da Marca" />
    <Jumbotron
      background={SECONDARY}
      title="Manual da Marca"
      padding={{ top: '50px', bottom: '100px' }}
    />
    <div
      className=""
      style={{
        backgroundColor: '#f1f1f1',
        paddingTop: '70px',
        paddingBottom: '70px',
      }}
    >
      <Container>
        <LogosDownload />
        <hr className="my-5 mt-5 mb-5" />
        <ChromaticPattern />
        <hr className="my-5 mt-5 mb-5" />
        <ProtectionArea />
        <hr className="my-5 mt-5 mb-5" />
        <MinimumSizing />
        <hr className="my-5 mt-5 mb-5" />
        <NotAllowedUsage />
        <hr className="my-5 mt-5 mb-5" />
        <CompleteManual />
      </Container>
    </div>
  </Layout>
);

BrandbookPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default BrandbookPage;
