import React from 'react';
import PropTypes from 'prop-types';

const ItemText = ({ title, text }) => (
  <>
    <p>
      <h2>{title}</h2>
    </p>
    {text && <p>{text}</p>}
  </>
);

ItemText.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
};

ItemText.defaultProps = {
  text: '',
};

export default ItemText;
