import React from 'react';
import { Col, Row } from 'reactstrap';
import ChromaticPatternItem from './ChromaticPatternItem';

import image from '../../../assets/manual_marca/padrao_cromatico/padrao_cromatico.png';

const ChromaticPattern = () => (
  <Row className="row">
    <Col className="col-12 col-md-5 col-lg-5 order-first">
      <p>
        <h2>Padrão cromático</h2>
      </p>
    </Col>
    <Col className="col-12 col-md-7 order-last">
      <Row className="row mb-6">
        <ChromaticPatternItem Image={image} title="Padrão Cromático" />
      </Row>
    </Col>
  </Row>
);

ChromaticPattern.propTypes = {};

export default ChromaticPattern;
