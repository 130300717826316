import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

const NotAllowedUsageItem = ({ Image, title }) => (
  <Col
    className="col-12 col-md-4"
    style={{ textAlign: 'center', padding: '5px' }}
  >
    <img
      src={Image}
      className="img-fluid"
      width={200}
      height={200}
      alt={title}
    />
    <p>{title}</p>
  </Col>
);

NotAllowedUsageItem.propTypes = {
  Image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default NotAllowedUsageItem;
