import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

const MinimumSizingItem = ({ Image, title }) => (
  <Col
    className="col-12 col-md-6"
    style={{ textAlign: 'center', padding: '5px' }}
  >
    <img
      src={Image}
      className="img-fluid"
      width={300}
      height={140}
      alt={title}
    />
  </Col>
);

MinimumSizingItem.propTypes = {
  Image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default MinimumSizingItem;
