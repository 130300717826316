import React from 'react';
import { Col, Row } from 'reactstrap';
import ProtectionAreaItem from './ProtectionAreaItem';
import ItemText from '../ItemText';

import image from '../../../assets/manual_marca/area_protecao/area_protecao.png';

const ProtectionArea = () => (
  <Row className="row">
    <Col className="col-12 col-md-5 col-lg-5 order-first">
      <ItemText
        title="Área de proteção"
        text="É preciso manter uma margem em torno do logotipo para protegê-lo. Assim, podemos garantir que ele não seja invadido por outros elementos, que não fique muito próximo às linhas de corte do material em que será aplicado, além de evitar vazamento de cores na hora da impressão."
      />
    </Col>
    <Col className="col-12 col-md-7 order-last">
      <Row className="row mb-6">
        <ProtectionAreaItem
          Image={image}
          alt="Área de proteção"
          title="A margem é definida pela medida da metade da altura da marca."
        />
      </Row>
    </Col>
  </Row>
);

ProtectionArea.propTypes = {};

export default ProtectionArea;
