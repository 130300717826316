import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

const ChromaticPatternItem = ({ Image, title }) => (
  <Col className="col-12" style={{ textAlign: 'center', padding: '5px' }}>
    <img
      src={Image}
      className="img-fluid"
      width={450}
      height={270}
      alt={title}
    />
  </Col>
);

ChromaticPatternItem.propTypes = {
  Image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ChromaticPatternItem;
