import React from 'react';
import { Col, Row } from 'reactstrap';
import DownloadButton from '../DownloadButton';
import ItemText from '../ItemText';

const CompleteManual = () => (
  <Row className="row">
    <Col className="col-12 col-md-5 col-lg-5 order-first">
      <ItemText title="Manual completo" />
    </Col>
    <Col className="col-12 col-md-7 order-last">
      <p>Clique abaixo para fazer o download do manual de marca completo.</p>
      <DownloadButton
        size="lg"
        href="/manual_marca/brandbook_central_do_frete.pdf"
        fileName="brandbook_central_do_frete.pdf"
      >
        Download (.pdf)
      </DownloadButton>
    </Col>
  </Row>
);

CompleteManual.propTypes = {};

export default CompleteManual;
