import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const DownloadButton = ({
  size,
  className,
  color,
  href,
  fileName,
  children,
}) => {
  const handleDownload = e => {
    e.preventDefault();
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button
      className={`m-1 ${className || ''}`}
      size={size}
      color={color}
      onClick={handleDownload}
    >
      {children}
    </Button>
  );
};

DownloadButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  href: PropTypes.string.isRequired,
  fileName: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

DownloadButton.defaultProps = {
  className: '',
  size: '',
  color: 'primary',
};

export default DownloadButton;
