import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import DownloadButton from '../DownloadButton';

const LogoItem = ({ Image, title, logosToDownload }) => (
  <Col
    className="col-12 col-md-6"
    style={{ textAlign: 'center', padding: '5px' }}
  >
    <img
      src={Image}
      className="img-fluid"
      width={315}
      height={250}
      alt={title}
    />
    <p className="mt-2 mb-1 text-center">{title}</p>
    {logosToDownload &&
      logosToDownload.map(logo => (
        <DownloadButton
          key={logo.id}
          size="sm"
          className="m-1"
          href={logo.href}
          fileName={logo.fileName}
        >
          {logo.title}
        </DownloadButton>
      ))}
  </Col>
);

LogoItem.propTypes = {
  Image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  logosToDownload: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      fileName: PropTypes.string.isRequired,
    })
  ),
};

LogoItem.defaultProps = {
  logosToDownload: [],
};

export default LogoItem;
