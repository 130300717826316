import React from 'react';
import { Col, Row } from 'reactstrap';
import MinimumSizingImage from './MinimumSizingItem';
import ItemText from '../ItemText';

import DigitalImage from '../../../assets/manual_marca/dimensionamento_minimo/digital.png';
import PrintedImage from '../../../assets/manual_marca/dimensionamento_minimo/impresso.png';

const MinimumSizing = () => (
  <Row className="row">
    <Col className="col-12 col-md-5 col-lg-5 order-first">
      <ItemText
        title="Dimensionamento mínimo"
        text="Para garantir a reprodução legível da marca foi estabelecida uma redução máxima, não podendo haver nenhum tipo de alteração nas formas e espaçamentos para não comprometer a legibilidade."
      />
    </Col>
    <Col className="col-12 col-md-7 order-last">
      <Row className="row mb-6">
        <MinimumSizingImage Image={DigitalImage} title="Digital" />
        <MinimumSizingImage Image={PrintedImage} title="Impresso" />
      </Row>
    </Col>
  </Row>
);

MinimumSizing.propTypes = {};

export default MinimumSizing;
